// Disable console
/*var console = {};
console.log = function(){};
window.console = console;
*/

window._stack.push(function(di) {
    console.log("Everything ready!");

    di.getService('page').on('error', function (evt) {
        if (evt && typeof evt.data !== 'undefined')
            console.error(evt.data);
        else
            console.error(evt);
    });
});

jQuery.fn.outerHTML = function() {
    return (this[0]) ? this[0].outerHTML : '';
};

if (typeof $.fn.dataTable !== 'undefined') {
    $.fn.dataTable.moment = function ( format, locale ) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift( function ( d ) {
            return moment( d, format, locale, true ).isValid() ?
                'moment-'+format :
                null;
        } );

        // Add sorting method - use an integer for the sorting
        types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
            return moment( d, format, locale, true ).unix();
        };
    };

    $.fn.dataTable.moment('D.M.YYYY HH:mm')

    // Custom filtering
    $.fn.dataTable.ext.search.unshift(
        function( settings, data, dataIndex ) {
            var result = true;
            var table = $(settings['nTable']);
            for (var key in data) {
                // If there is checkbox all our cols are moved by one
                var target = data[key];
                var filters = table.find('.filters th[data-col="' + key +'"] .datatable-filter');

                filters.each(function() {
                    var filter = $(this);
                    var val = filter.val();
                    var type = filter.data('filter-type');

                    if (result === false)
                        return;

                    if (type === 'text' || type === 'select') {
                        if (target !== null && val !== null) {
                            result = target.toLowerCase().search(val.toLowerCase()) !== -1;
                        }
                    } else if (type === 'date-since' || type === 'date-until') {
                        var format = filter.data('format');
                        var date = moment(val, format, true);
                        var time = moment(target, format, true);

                        if (date.isValid()) {
                            if (type === 'date-since') {
                                result = time >= date;
                            } else {
                                result = time <= date;
                            }
                        }
                    } else if (type === 'price-min' || type === 'price-max') {
                        var valPrice = parseFloat(val);
                        if (!Number.isNaN(valPrice)) {
                            var priceMin = 0;
                            var priceMax = 0;
                            var targetPrice = target.replace(',', '.');
                            var split = targetPrice.split('-');
                            if (split.length > 1) {
                                priceMin = parseFloat(split[0]);
                                priceMax = parseFloat(split[1]);
                            } else {
                                priceMin = parseFloat(targetPrice);
                                priceMax = parseFloat(targetPrice);
                            }

                            if (type === 'price-min') {
                                result = priceMin >= valPrice;
                            } else {
                                result = priceMax <= valPrice;
                            }
                        }
                    }
                });

                if (result === false)
                    break;
            }

            return result;
        }
    );
}

function select(selector, target) {
    if (typeof target === "undefined") {
        return $(selector);
    }

    return $('#' + target).find(selector);
}

function initBootstrapTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body',
    });
}

function checkCompanyIdentificationNumber(number) {

    if(number.length == 0)
        return true;
    if(number.length != 8)
        return false;

    var sum = 0;
    var digits = number.split('');
    for(var i = 0; i < 7; i++) {
        sum += (parseInt(digits[i]) * (8 - i));
    }

    sum = sum % 11;
    var control = 11 - sum;

    if(sum == 1)
        control = 0;
    if(sum == 0)
        control = 1;
    if(sum == 10)
        control = 1;

    if(parseInt(digits[7]) != control)
        return false;

    return true;
}

function addOptionsToSelect($select, options, clear)
{
    if (typeof clear === 'undefined')
        clear = true;

    if (clear)
        $select.empty();

    var firstKey = false;
    for (var key in options) {
        if (firstKey === false)
            firstKey = key;

        $select.append($('<option>', { value: key, text: options[key] }));
    }

    $select.val(firstKey);
    $select.trigger('change');
    ///$select.children().first().attr('selected', true);
}

var loadingOverlayManager = null;
function showLoadingOverlay() {
    getLoadingOverlayManager().show();
}

function hideLoadingOverlay() {
    getLoadingOverlayManager().hide();
}

function getLoadingOverlayManager() {
    if (loadingOverlayManager) {
        return loadingOverlayManager;
    }

    loadingOverlayManager = new MeebLoadingOverlay();
    return loadingOverlayManager;
}

function initializeCrud(di, target) {
    dataTablesInit(target);
    initPrettySelect(target);
    initIChecks(target);
    initSummernote(target);
    initDatePicker(target);
    initVisibilityDependentFields(target);
    initFallbackFields(target);
    initColorPicker(target);
    initTableCrudImage(target);

    new AutoGenerateManager(di, target);
}

function initTableCrudImage(target) {
    let element = $(document);
    if (typeof target !== "undefined") {
        element = $('#' + target);
    }

    element.off('.tcimg');
    element.on('click.tcimg', '.table-crud-image', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let src = $(this).data('image');
        let body = $('body');
        body.addClass('image-view-overlay-open');
        body.append($('<div class="image-view-overlay"><div class="content"><img src="' + src + '"></div></div>'));
        body.on('click.tcimg-wrapper', function() {
            body.removeClass('image-view-overlay-open');
            body.off('.tcimg-wrapper');
            $('.image-view-overlay').remove();
        })
    });
}


function initColorPicker(target) {
    const selector = '.color-picker';
    select(selector, target).each(function() {
        //TODO pridat bootstrap color
        let colors = $(this).data('default-colors');

        $(this).colorpicker({
            hexNumberSignPrefix: true,
            format: 'hex',
            align: 'left',
            colorSelectors: colors,
            customClass: 'colorpicker-2x',
            sliders: {
                saturation: {
                    maxLeft: 200,
                    maxTop: 200
                },
                hue: {
                    maxTop: 200
                },
                alpha: {
                    maxTop: 200
                }
            }
        });
    });
}

function addRevertButton($element) {
    var revertButton = $('<a href="#" class="fallback-icon btn btn-danger btn-xs"><i class="fa fa-undo"></i></a>');

    $element.closest('.from-group').css('display', 'relative');
    var closest = $element.closest('div');
    closest.append(revertButton);

    closest.find('.fallback-icon').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        $element.val($element.data('fallback'));
        $element.attr('readonly', true);

        revertButton.remove();
    });

    return revertButton;
}

function initFallbackField($element) {
    var fallback = $element.data('fallback');
    var val = $element.val();
    if ($element.attr('readonly')) {
        return false;
    }

    var revertButton = false;
    if (val === fallback) {
        $element.attr('readonly', true);
        $element.click(function() {
            $(this).attr('readonly', false);
            $(this).focus();

            revertButton = addRevertButton($element);
        });

        $element.blur(function() {
            if ($element.data('fallback') === $element.val()) {
                $element.attr('readonly', true);

                if (revertButton) {
                    revertButton.remove();
                    revertButton = false;
                }
            }
        });
    } else {
        revertButton = addRevertButton($element);
    }
}

function addSummernoteRevertButton($element, noteEditingArea) {
    var revertButton = $('<a href="#" class="fallback-icon btn btn-danger btn-xs"><i class="fa fa-undo"></i></a>');

    noteEditingArea.css('position', 'relative');
    noteEditingArea.append(revertButton);

    noteEditingArea.find('.fallback-icon').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        $element.summernote('code', $element.data('fallback'));
        $element.summernote('disable');
        $(this).remove();
    });

    return revertButton;
}

function initFallbackFields(target) {
    const inputSelector = 'input[data-fallback]';
    const textAreaSelector = 'textarea[data-fallback]';

    select(inputSelector, target).each(function() {
        let type = $(this).attr('type');
        if (type != 'hidden' && type != 'checkbox') {
            initFallbackField($(this));
        }
    });

    select(textAreaSelector, target).each(function() {
        let $this = $(this);
        let revertButtonTemplate = $('<a href="#" class="fallback-icon btn btn-danger btn-xs"><i class="fa fa-undo"></i></a>');

        if ($this.hasClass('wysiwyg-editor')) {
            if ($this.val() === $this.data('fallback')) {
                $this.summernote('disable');

                let noteEditingArea = $this.closest('div').find('.note-editing-area');
                noteEditingArea.click(function () {
                    $this.summernote('enable');
                    $this.summernote('focus');

                    addSummernoteRevertButton($this, noteEditingArea);
                });

                $this.on('summernote.blur', function () {
                    if ($this.val() == $this.data('fallback')) {
                        $this.summernote('disable');

                        if (revertButtonTemplate) {
                            revertButtonTemplate.remove();
                        }
                    }
                });
            } else {
                addSummernoteRevertButton($this, $this.closest('div').find('.note-editing-area'));
            }
        } else {
            initFallbackField($this);
        }
    });
}

function initNestedCheckbox() {
    console.log('init');
    $('.nested-checkbox input[type="checkbox"]').on('ifChanged', function () {
        var $this = $(this);

        var checked = $this.iCheck('update')[0].checked;
        //var checked = typeof $this.attr('checked') !== "undefined" && $this.attr('checked') !== false;
        $this.closest('li').find('input[type="checkbox"]').each(function() {
            $(this).iCheck(checked ? 'check' : 'uncheck');
        });

        /*
        var parent = $this.closest('input[type="checkbox"]');
        if (parent.length > 0) {
            var checkedCount = $this.find('input[type="checkbox"]:checked').length;
            var totalCount = $this.find('input[type="checkbox"]').length;

            if (checkedCount === 0) {
                parent.prop('indeterminate', false);
                parent.
            }
        }*/
    });
}

function initCheckboxSearch(target, parent) {
    $(target).on('keyup.filter', function () {
        var search = $(this).val().toLowerCase();

        $(this).closest(parent).find('input[type="checkbox"]').each(function () {
            var elem = $(this);
            var target = elem.closest('label');

            var text = target.text().toLowerCase();
            if (text.indexOf(search) != -1) {
                target.show();
                target.next().show();
            } else {
                target.hide();
                target.next().hide();
            }
        });
    });
}

function destroyCheckboxSearch(target) {
    $(target).off('.filter');
}

function initLightboxGallery() {
    $('.lightBoxGallery').click(function (e) {
        var target = e.target;
        if ($(target).hasClass('lightbox-img')) {
            var link = target.src ? target.parentNode : target;
            var options = {index: link, event: event};
            var links = $(this).find('.lightbox-img');

            blueimp.Gallery(links, options);
        }
    });
}

function initSlimScroll() {
    $('.full-height-scroll').slimscroll({
        height: '100%'
    });
}

function initDatePickerElement(elem, clearButton) {
    var $this = elem;
    var format = $this.data('format');
    var locale = $this.data('locale');

    return $this.datetimepicker({
        format: format,
        locale: locale,
        showTodayButton: true,
        showClear: clearButton,
        //debug: true,
    });
}

function initDatePicker(target) {
    const selector = '.date-input';

    select(selector, target).each(function () {
        initDatePickerElement($(this), false);
    });
};

function destroyDatePicker(target) {
    const selector = '.date-input';

    select(selector, target).each(function () {
        let $this = $(this);
        if (typeof $this.datetimepicker() === 'function') {
            $this.datetimepicker('destroy');
        }
    });
};

function updateSnippets(data) {
    if (typeof data.snippets !== undefined) {
        for (var key in data.snippets) {
            $('#' + key).html(data.snippets[key]);
        }
    }

    if (typeof data.flashes !== "undefined") {
        for (var key in data.flashes) {
            if (data.flashes && data.flashes[key] !== null) {
                data.flashes[key].forEach(function(message) {
                    toastr[message.type](message.message);
                });
            }
        }
    }
}

function supports_html5_storage() {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
        return false;
    }
}


function dataTablesInit(target) {
    const selector = '.dataTables';

    var langs = {
        cs_CZ: {
            "sEmptyTable":     "Tabulka neobsahuje žádná data",
            "sInfo":           "Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů",
            "sInfoEmpty":      "Zobrazuji 0 až 0 z 0 záznamů",
            "sInfoFiltered":   "(filtrováno z celkem _MAX_ záznamů)",
            "sInfoPostFix":    "",
            "sInfoThousands":  " ",
            "sLengthMenu":     "Zobraz záznamů _MENU_",
            "sLoadingRecords": "Načítám...",
            "sProcessing":     "Provádím...",
            "sSearch":         "Hledat:",
            "sZeroRecords":    "Žádné záznamy nebyly nalezeny",
            "oPaginate": {
                "sFirst":    "První",
                "sLast":     "Poslední",
                "sNext":     "Další",
                "sPrevious": "Předchozí"
            },
            "oAria": {
                "sSortAscending":  ": aktivujte pro řazení sloupce vzestupně",
                "sSortDescending": ": aktivujte pro řazení sloupce sestupně"
            }
        },
        en_US: {
            "sEmptyTable":     "No data available in table",
            "sInfo":           "Showing _START_ to _END_ of _TOTAL_ entries",
            "sInfoEmpty":      "Showing 0 to 0 of 0 entries",
            "sInfoFiltered":   "(filtered from _MAX_ total entries)",
            "sInfoPostFix":    "",
            "sInfoThousands":  ",",
            "sLengthMenu":     "Show _MENU_ entries",
            "sLoadingRecords": "Loading...",
            "sProcessing":     "Processing...",
            "sSearch":         "Search:",
            "sZeroRecords":    "No matching records found",
            "oPaginate": {
                "sFirst":    "First",
                "sLast":     "Last",
                "sNext":     "Next",
                "sPrevious": "Previous"
            },
            "oAria": {
                "sSortAscending":  ": activate to sort column ascending",
                "sSortDescending": ": activate to sort column descending"
            }
        }
    };

    select(selector, target).each(function () {
        var columnCount = $(this).find('thead td').length - 1;
        if ( $.fn.dataTable.isDataTable(this) ) {
            $(this).DataTable();
        } else {
            var $this = $(this);
            var orderColumn = $this.data('sort-column');
            var orderDirection = $this.data('sort-order');
            var langCode = $this.data('lang-code');
            var lang = langs[langCode];
            var emptyTableMessage = $this.data('empty-table-message');

            if (typeof emptyTableMessage !== 'undefined') {
                lang.sEmptyTable = emptyTableMessage;
            }

            var columnDefs = [
                { "orderable": false, "targets": columnCount }
            ];

            if ($this.data('checkbox-select')) {
                columnDefs = [
                    { "targets": 0, "checkboxes": { 'selectRow': true }},
                    { "orderable": false, "targets": columnCount }
                ];
            }

            var options = {
                "language": lang,
                "destroy": true,
                "pageLength": 25,
                "processing": true,
                "columnDefs": columnDefs,
                'select': {
                    'style': 'multi'
                },
                "rowCallback": function (row, data, dataIndex, cells) {
                    if (typeof data['DT_RowStyle'] !== "undefined") {
                        $(row).attr('style', data['DT_RowStyle']);
                    }
                },
                "stateSave": true,
                "stateSaveCallback": function(settings, data) {
                    var tableIdentifier = $(settings.nTable).data('name');
                    if (typeof tableIdentifier === 'undefined')
                        tableIdentifier = (window.location.pathname).replace(/[- ._/]*/g, '');

                    var filterIdentifier = tableIdentifier + 'filters';

                    if (supports_html5_storage()) {
                        window.localStorage.setItem(tableIdentifier, JSON.stringify(data));

                        var table = $(settings['nTable']);
                        var filters = table.find('.filters th');
                        var result = new Object();
                        filters.each(function () {
                            var data = $(this).attr('data-col');
                            var values = new Array();

                            $(this).find('.datatable-filter').each(function() {
                                values.push($(this).val());
                            });

                            result[data] = values;
                        });

                        var visibility = table.find('.filters').is(':visible');
                        window.localStorage.setItem(filterIdentifier, JSON.stringify({filters: result, visibility: visibility}));
                    }
                },
                "stateLoadCallback": function(settings) {
                    var tableIdentifier = $(settings.nTable).data('name');
                    if (typeof tableIdentifier === 'undefined')
                        tableIdentifier = (window.location.pathname).replace(/[- ._/]*/g, '');

                    var filterIdentifier = tableIdentifier + 'filters';
                    if (supports_html5_storage()) {
                        var data = JSON.parse(window.localStorage.getItem(filterIdentifier));
                        var table = $(settings['nTable']);
                        if (data && typeof data.filters !== 'undefined') {
                            for (var key in data.filters) {
                                var i = 0;
                                table.find('.filters th[data-col="' + key +'"] .datatable-filter').each(function() {
                                    $(this).val(data.filters[key][i]);
                                    i++;
                                });
                            }

                            if (data.visibility) {
                                table.find('.filters').show();
                                table.find('.filter-control').toggle();
                            }
                        }

                        var data = window.localStorage.getItem(tableIdentifier);
                        return JSON.parse(data);
                    }
                    return false;
                },
                "initComplete": function() {
                    $('.variant-table .dataTables_wrapper').removeClass('form-inline');
                }
            };

            if (orderColumn && orderDirection) {
                options["order"] = [[ orderColumn, orderDirection]];
            }

            if (!orderColumn) {
                options['ordering'] = false;
            }

            var serverSideLink = $(this).data('server-side-link');
            if (typeof serverSideLink !== 'undefined') {
                options.serverSide = true;
                options.ajax = {
                    url: serverSideLink,
                    type: 'POST',
                    data: function(data, settings){
                        console.log(data);
                        console.log(settings);
                        var table = $(settings['nTable']);
                        var filters = table.find('.filters th');
                        var result = new Object();
                        filters.each(function () {
                            var data = $(this).attr('data-col');
                            var values = new Array();

                            $(this).find('.datatable-filter').each(function() {
                                values.push($(this).val());
                            });

                            result[data] = values;
                        });

                        data.filters = result;
                    }
                };
            }

            var table = $(this).DataTable(options);
            var isLazyLoading = typeof $(this).data('server-side-link') !== 'undefined';

            $(this).find('.datatable-filter').change(function() {
                table.draw();
            });

            var handle = false;
            $(this).find('.datatable-filter').keyup(function() {
                if (isLazyLoading) {
                    if (handle !== false)
                        clearTimeout(handle);

                    handle = setTimeout(function () {
                        table.draw();
                    }, 500);
                } else {
                    table.draw()
                }
            });

            $(this).find('.date-filter').each(function () {
                initDatePickerElement($(this), true).on('dp.change', function() { table.draw(); });
            });

            $(this).find('.head-filter-toggle').click(function() {
                var target = $(this).closest('table');
                target.find('.filters').toggle();
                target.find('.filter-control').toggle();

                table.state.save();
            });
        }
    });
}

function dataTablesDestroy(target) {
    const selector = '.dataTables';

    select(selector, target).each(function(elem) {
        if (typeof elem.DataTable !== "undefined") {
            elem.DataTable('destroy')
        }
    });
}

function initPrettySelect(target) {
    const selector = '.pretty-select';

    let options = {
        theme: 'bootstrap',
    };

    select(selector, target).each(function () {
        let $this = $(this);
        if (typeof $this.data('tag') !== "undefined") {
            options.tags = true;
        }

        if (typeof $this.data('placeholder') !== "undefined") {
            options.placeholder = $this.data('placeholder');
        }

        if ($this.attr('readonly')) {
            options.readonly = true;
            options.disabled = true;
        }

        if (typeof $this.data('ajax') !== "undefined") {
            options.minimumInputLength = 3;
            options.ajax = { // instead of writing the function to execute the request we use Select2's convenient helper
                url: $this.data('ajax'),
                dataType: 'json',
                quietMillis: 250,
                data: function (term, page) {
                    return {
                        q: term, // search term
                    };
                },
                processResults: function (data, page) { // parse the results into the format expected by Select2.
                    // since we are using custom formatting functions we do not need to alter the remote JSON data
                    var result = $.map(data, function (value, key) {
                        return {id: key, text: value};
                    });
                    console.log(result);
                    return {results: result};
                },
                cache: true
            };
        }

        $this.select2(options);
    });
}

function destroyPrettySelect() {
    $('.pretty-select').each(function () {
        var $this = $(this);
        if ($this && $this.data('select2') && typeof $this !== "undefined" && typeof $this.select2 !== "undefined") {
            $this.select2('destroy');
        }
    });
}

function initIChecks(target) {
    const selector = '.i-checks';

    select(selector, target).iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
    });
}

function moveCursorToElement(node, start) {
    if (window.getSelection) {  // all browsers, except IE before version 9
        if (node instanceof Node) {
            var range = document.createRange();
            range.selectNode(node);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            if (start) {
                window.getSelection().collapseToStart();
            } else {
                window.getSelection().collapseToEnd();
            }
        }
    }
}

var summernoteSelectedNode = false;
function selectNode(node) {
    if (window.getSelection) {  // all browsers, except IE before version 9
        var range = document.createRange();
        range.selectNode(node);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        summernoteSelectedNode = node;
    }
}

function createSummernoteList(dataHolder, title, icon, control, context) {
    let ui = $.summernote.ui;

    let forms = $(dataHolder).data('forms');
    let element = $('<ol></ol>');
    for (let key in forms) {
        let li = $('<li></li>').html(forms[key]).data('id', key);
        element.append(li);
    }

    let button = ui.buttonGroup([
        ui.button({
            className: 'dropdown-toggle',
            contents: '<i class="fa ' + icon + '"></i>',
            tooltip: title,
            title: title,
            container: false,
            data: {
                toggle: 'dropdown'
            },
            click: function () {
                context.invoke('editor.saveRange');
            }
        }),
        ui.dropdown({
            className: 'summernote-form-dropdown',
            contents: element,
            callback: function ($dropdown) {
                $dropdown.find('li').each(function () {
                    $(this).click(function(e) {
                        let id = $(this).data('id');
                        let formString = '{control ' + control + ' ' +id + '}';
                        // We restore cursor position and text is inserted in correct pos.
                        context.invoke('editor.restoreRange');
                        context.invoke('editor.focus');
                        //context.invoke("editor.insertText", formString);

                        // Create new node that contains form identifier
                        let node = document.createElement('div');
                        node.className = "form-wrapper";

                        let span = document.createElement('span');
                        span.className = "form-bg";

                        let text = document.createTextNode(formString);
                        span.appendChild(text);
                        node.appendChild(span);

                        context.invoke('editor.insertNode', node);
                        context.invoke('editor.insertNode', document.createElement('br'));
                        e.preventDefault();
                    });
                });
            }
        })
    ]);

    return button.render();
}

function initSummernote(target) {
    const selector = '.wysiwyg-editor';

    let galleryButton = function (context) {
        let ui = $.summernote.ui;

        // create button
        let button = ui.button({
            contents: '<i class="fa fa-image"/>',
            tooltip: 'Vložit obrázek',
            title: 'Vložit obrázek',
            container: false,
            click: function () {
                $(this).closest('.crud-component').find('.multifile-gallery').first().modal('show');
            }
        });

        return button.render();   // return button as jquery object
    };

    let contactList = function(context) {
        return createSummernoteList('#form-data-holder', 'Vložit formulář', 'fa-address-card-o', 'contactForm', context);
    };

    let articleList = function(context) {
        return createSummernoteList('#articles-data-holder', 'Vložit odkaz na článek' , 'fa-book', 'articleLink', context);
    }

    let productList = function(context) {
        return createSummernoteList('#products-data-holder', 'Vložit odkaz na produkt', 'fa-shopping-cart', 'productLink', context);
    }

    select(selector, target).each(function () {
        var buttons = {
            form: contactList,
            article: articleList,
            product: productList,
        };

        if ($(this).hasClass('editor-gallery')) {
            buttons.image = galleryButton;
        }

        var summernote = $(this).summernote({
            height: 150,
            lang: 'cs-CZ',
            disableDragAndDrop: true,
            onCreateLink : function(originalLink) {
                return originalLink;
            },
            callbacks: {
                onPaste: function (e) {
                    var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                    e.preventDefault();
                    document.execCommand('insertText', false, bufferText);
                },
            },
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['style']],
                ['font', ['bold', 'italic', 'clear']],
                ['script', ['superscript', 'subscript']],
                ['fontsize', ['fontsize']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link', 'table', 'image', 'form', 'article', 'product']],
                ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
            ],

            buttons: buttons,
        });

        var content = false;
        summernote.on('summernote.change', function(we, contents, $editable) {
            var content = $('<div></div>');
            var html = $(contents);
            if (html.length === 0) {
                html = $('<p><br></p>');
            }

            content.html(html);

            var wrappers = html.filter('.form-wrapper');

            var selection = window.getSelection();
            var selectionTarget = false;
            wrappers.each(function () {
                var target = $(this).find('span').html();
                if (typeof target !== 'undefined') {
                    var match = target.match(/^{control .* [0-9]+}$/);
                    if (!match) {
                        var form = target.replace(/{?control .* [0-9]+}?/, '');
                        var anchor = $('<p class="anchor"></p>');
                        if (form.length) {
                            anchor.html(form);
                            $(this).after(anchor);
                            selectionTarget = $(this).next();
                        }

                        $(this).remove();
                    }
                }
            });

            var result = content.html();
            if (result !== contents) {
                summernote.summernote("code", content.html());

                var cursorAnchor = summernote.parent().find('.note-editable').find('.anchor');
                moveCursorToElement(cursorAnchor[0], false);
                cursorAnchor.removeClass('anchor');
            }
        });

        $(this).parent().find('.note-editing-area').on('mouseup', function () {
            if (window.getSelection) {
                var selection = window.getSelection();
                var selected = false;
                $('.form-wrapper').each(function () {
                    if (selection.containsNode(this, true)) {
                        selectNode(this);
                        selected = true;
                    }
                });

                if (selected === false) {
                    summernoteSelectedNode = false;
                }
            }
        });

        summernote.on('summernote.keydown', function(we, e) {
            if (e.keyCode === 13) {
                selectionEnter(e, summernote);
            }
        });

        summernote.on('summernote.keyup', function(we, e) {
            // Only process arrows, delete and backspace
            if (window.getSelection) {
                var selection = window.getSelection();
                if ((e.keyCode >= 37 && e.keyCode <= 40)) {
                    selectionArrows(e.keyCode, selection, summernote);
                } else if (e.keyCode === 13 && summernoteSelectedNode) {
                    // Poresil si keydown nedelame nic
                } else {
                    summernoteSelectedNode = false;
                }
            }
        });

        summernote.closest('form').on('submit', function() {
            if (summernote.summernote('codeview.isActivated')) {
                summernote.summernote('codeview.deactivate');
            }
        });
    });
}

function selectionEnter(event, summernote) {
    if (summernoteSelectedNode && window.getSelection) {
        var selection = window.getSelection();
        $('.form-wrapper').each(function () {
            if (selection.containsNode(this, true)) {
                $(this).before('<p><br /></p>');
                $(this).addClass('anchor');

                var parent = $(summernote).parent();
                var code = parent.find('.note-editable').html();
                summernote.summernote('code', code);
                var anchor = parent.find('.anchor');
                selectNode(anchor[0]);
                anchor.removeClass('anchor');

                event.preventDefault();
                event.stopPropagation();
                return false;
            }
        });
    }
}

function isArrow(keyCode) {
    return keyCode >= 37 && keyCode <= 40;
}

function selectionArrows(keyCode, selection, summernote) {
    if (!selection)
        return false;

    var selected = false;
    $('.form-wrapper').each(function () {
        if (selection.containsNode(this, true)) {
            if (summernoteSelectedNode && isArrow(keyCode)) {
                if (keyCode == 38 || keyCode == 37) {
                    var node = $(summernoteSelectedNode).prev();
                    if (node.length > 0) {
                        moveCursorToElement(node[0], true);
                    }
                } else {
                    var node = $(summernoteSelectedNode).next();
                    if (node.length > 0) {
                        moveCursorToElement(node[0], true);
                    } else {
                        var editor = $(summernote).parent().find('.note-editable');
                        editor.append($('<p class="anchor">&nbsp</p>'));

                        var anchor = editor.find('.anchor');
                        moveCursorToElement(anchor[0], true);
                        anchor.removeClass('anchor');
                    }
                }
            } else {
                selectNode(this);
                selected = true;
            }
        }
    });

    if (selected === false) {
        summernoteSelectedNode = false;
    }
}

function destroySummernote() {
    $('.summernote').summernote('destroy');
}

function destroyGallery() {
    if($("#frm-gallery-uploadForm-file").length){
        $("#frm-gallery-uploadForm-file").fileupload('destroy');
        console.log("Gallery destroyed.");
    }

}

$(document).on('click','.modal-submit',function () {
    $(this).closest('.modal').modal('toggle');
});

function recomputeNestableHeight(sortableOnly) {
    if (!sortableOnly)
        $("#structure-container").css('height',($("#structure-container").height()+40)+'px');

    $("#structure-container").css('height',($("#structure-container .sortable").height()+40)+'px');
}

function initReorderList() {
    $('.drag-and-drop-reorder').each(function () {
        var link = $(this).data('link');

        Sortable.create(this, {
            sort: true,

            onStart: function () {
                if (window.globalUpload)
                    window.globalUpload.overlay.disable();
            },
            onEnd: function () {
                if (window.globalUpload)
                    window.globalUpload.overlay.enable();
            },
            onSort: function (e) {
                showLoadingOverlay();
                var itemId = $(e.item).data('id');
                var newOrder = e.newIndex;

                var request = $.post(link, {'item': itemId, 'order': newOrder }).done(function (data) {
                    updateSnippets(data);
                    hideLoadingOverlay();
                });
            }
        });
    });
}

function initSortable() {
    $('.sortable').each(function () {
        let isTree = true;
        if (typeof $(this).attr('data-list') !== 'undefined') {
            isTree = false;
        }

        let listType = 'ol';
        if (typeof $(this).attr('data-type') !== 'undefined') {
            listType = $(this).attr('data-type');
        }

        let maxLevel = 0;
        if (typeof $(this).attr('data-max-level') !== 'undefined') {
            maxLevel = $(this).attr('data-max-level');
        }

        $(this).nestedSortable({
            handle: 'div',
            items: 'li',
            toleranceElement: '> div',
            listType: listType,
            isTree: isTree,
            maxLevels: maxLevel,
            startCollapsed: true,
            relocate: function () {
                var order =  $('ol.sortable').nestedSortable('toArray', { startDepthCount: 0});
                var link = $('ol.sortable').data('link');

                var openedLists = [];
                $('.mjs-nestedSortable-expanded').each(function () {
                    openedLists.push($(this).data('id'));
                });

                showLoadingOverlay();
                $.ajax({
                    url: link,
                    method: 'POST',
                    data: {
                        order: order,
                    },
                }).done(function (data) {
                    updateSnippets(data);
                    recomputeNestableHeight(false);

                    openedLists.forEach(function (value) {
                        $('.dd-item[data-id="' + value + '"]').each(function () {
                            if (!$(this).hasClass('.mjs-nestedSortable-collapsed')) {
                                $(this).toggleClass('mjs-nestedSortable-collapsed').toggleClass('mjs-nestedSortable-expanded');
                                $(this).find('.disclose i.fa').toggleClass('fa-plus').toggleClass('fa-minus');
                            }
                        });
                    });

                    recomputeNestableHeight(true);
                    hideLoadingOverlay();
                });
            },
        }).after(function () {
            recomputeNestableHeight(false);

            $('.disclose').off('.disclose');
            $('.disclose').on('click.disclose', function() {
                $(this).closest('li').toggleClass('mjs-nestedSortable-collapsed').toggleClass('mjs-nestedSortable-expanded');
                $(this).children('i.fa').toggleClass('fa-plus').toggleClass('fa-minus');

                recomputeNestableHeight(true);
            });
        });
    });
}

function destroySortable() {
    if (typeof $('.sortable').sortable !== "undefined" && $('.sortable').hasClass('ui-sortable')) {
        $('.sortable').sortable('destroy');
        $('.disclose').off('click');
    }
}

$(document).ready( function () {
    $("#structure-container").css('height',($(".ui-sortable").height()+40)+'px');
});

function changeVisibility(target, show, reverse) {
    if (show) {
        if (reverse) {
            target.hide();
        } else {
            target.show();
        }
    } else {
        if (reverse) {
            target.show();
        } else {
            target.hide();
        }
    }
}

function initVisibilityDependentFields(target) {
    const selector = '*[data-visibility-field]';
    select(selector, target).each(function () {
        let $this = $(this);
        let $parent = $this.closest('.form-group');

        let targetName = $this.data('visibility-field');
        let targetType = $this.data('visibility-type');
        if (typeof targetType === 'undefined') {
            targetType = '*';
        }

        let form = $this.closest('form');

        let targetIdentifier = targetType + '[name="' + targetName + '"]';

        let isReversed = $this.data('visibility-reversed');
        let targetValue = $this.data('visibility-value');

        let $target = form.find(targetIdentifier);

        if ($target.is(':checkbox')) {
            changeVisibility($parent, $target.is(':checked'), isReversed);

            $target.on('ifChanged', function () {
                let checked = $(this).is(':checked');
                changeVisibility($parent, checked, isReversed);
            });
            $target.on('change', function() {
                let checked = $(this).is(':checked');
                changeVisibility($parent, checked, isReversed);
            });
        } else if ($target.is('select')) {
            changeVisibility($parent, $target.val() === targetValue, isReversed);

            $target.on('select2:select', function(e) {
                changeVisibility($parent, $(this).val() === targetValue, isReversed);
            });
            $target.on('change', function() {
                changeVisibility($parent, $(this).val() === targetValue, isReversed);
            });
        } else {
            changeVisibility($parent, $target.val() === targetValue, isReversed);

            $target.change(function () {
                changeVisibility($parent, $(this).val() === targetValue, isReversed);
            });
        }
    });
}

$(document).on('keyup','.meeb-menu-text-input', function(){
    $(this).css('width', (($(this).val().length) * 7) + 'px');

    $.ajax({
        url: '?do=editItemTitle',
        data: {
            translationId: $(this).data('translation-id'),
            title: $(this).val(),
        }
    });

}).each(function () {
    $(this).css('width', (($(this).val().length) * 7) + 'px');
});

$(document).on('click', "#meter-calculate-lower", function () {
    var width;

    if($.isNumeric($("#price-meter").val())){
        $(".variant-item").each( function () {

            width = parseInt($(this).find(".variant-primary-col").text()) / 1000;
            var price = parseFloat($("#price-meter").val());
            newPrice = price * width;

            var catalogue = $(this).find(".td-catalogue_price input").val(newPrice.toFixed(2));
            var discount = parseFloat($(this).find(".td-discount_lower input").val());
            var price = $(this).find(".td-price_lower input").val(((1 - (discount / 100)) * newPrice).toFixed(2));
        });
    }
});

$(document).on('click',"#meter-calculate-higher", function () {
    var width;

    if($.isNumeric($("#price-meter").val())){

        var width;

        $(".variant-item").each( function () {
            width = parseInt($(this).find(".variant-primary-col").text()) / 1000;
            var price = parseFloat($("#price-meter").val());
            newPrice = price * width;

            var catalogue = $(this).find(".td-catalogue_price_higher input").val(newPrice.toFixed(2));
            var discount = parseFloat($(this).find(".td-discount_higher input").val());
            var price = $(this).find(".td-price_higher input").val(((1 - (discount / 100)) * newPrice).toFixed(2));
        });
    }
});

$(document).on('keyup','input.custom-edit-field', function () {
    $.ajax({
        url: "?do=editField",
        data: {
            "newValue": $(this).val(),
            "fieldName": $(this).data('field-name'),
            "entityId": $(this).data("entity-id"),
            "table": $(this).data("table"),
        }
    });
});