function MeebAutoUpdater(formId, formType)
{
    if(this.formExist() === 1){
        this.formId = formId;
        this.formType = formType;
        this.form = $('#'+formId);

        let inputs = this.form.find('input');
        let textareas = this.form.find('textarea');
        let self = this;

        inputs.each(function() {
            let val = $( this );
            self.processInputField(val);
        });

        textareas.each(function() {
            let val = $( this );
            self.processTextAreaField(val);
        });

        this.fillForm();
        this.form.submit(function() {
           self.clean();
        });
    }
}

MeebAutoUpdater.prototype.processInputField = function( field )
{
    let self = this;
    if (field.is(':checkbox')){
        $(field).on('ifToggled', function(event){
            self.serializeForm();
        });

        $(field).on('click', function () {
            self.serializeForm();
        })

        return;
    }
        if(!($(field).attr('readonly'))){
            field.on('keypress', function (event) {
                self.serializeForm();
            });
        }
    // ---- EXP ----



    // ---- EXP ----

    if($(field).attr('readonly')){
        field.on('change', function (event) {
            self.serializeForm();
        });
    }


}
MeebAutoUpdater.prototype.processTextAreaField = function(field) {

    if(typeof(field.summernote) !== "undefined" ){
        $(field).on('summernote.change', function(){
            self.serializeForm();
        })
    }
    else field.on('keypress', function(){
        self.serializeForm();
    })
    let inpType = 1;
    let self = this;



    if(!(field.is(':disabled'))) {
        field.on('keypress', function( event ) {
            self.serializeForm();
        });
    }
}

MeebAutoUpdater.prototype.getFormValueAsArray = function() {
    let updatedValue = this.form.serializeArray();
    this.form.find('input[ type = checkbox ]').each( function(){
        if($(this).attr('name'))
            updatedValue.push({
                name: $(this).attr('name'),
                value: $(this).is(':checked')
            })
    });

    return updatedValue;
}

MeebAutoUpdater.prototype.serializeForm = function() {
    this.saveUpdatedValue(this.getFormValueAsArray());
}

MeebAutoUpdater.prototype.saveUpdatedValue = function(e)
{
    localStorage.setItem(this.formType, JSON.stringify(e));
}

MeebAutoUpdater.prototype.deserializeForm = function()
{
    let storedValue = localStorage.getItem(this.formType);
    let storedValueSerialized = JSON.parse(storedValue);

    let filtered = storedValueSerialized.filter( function( value ){
        return value.name.substr(value.name.length - 2) === '[]';
    })
    if( filtered.length )
    {
        let temp = {};
        filtered.forEach( function ( value ){
            let name = value.name;
            if( typeof temp[name] === 'undefined' ){
                temp[name]= [ ];
            }
            temp[name].push( value.value );
        })
        storedValueSerialized = storedValueSerialized.filter( function( value ){
            return value.name.substr(value.name.length - 2) !== '[]';
        })
        for(let key in temp){
            storedValueSerialized.push({ name : key, value: temp[key]})
        }
    }

    let self = this;

    storedValueSerialized.forEach(function(value){
        self.deserializeItem(value.name, value.value);
    })
    this.form.find('[data-gallery-modal]').each(function() {
        $(this).data('setupFieldImage')();
    })
}

MeebAutoUpdater.prototype.deserializeItem = function(key, value){
    let item = this.form.find('*[ name = "' + key + '"]');
    if(item.is('[type = text]')){ item.val(value)}

    if(item.hasClass('wysiwyg-editor')) {
        item.summernote('code', value );
    }

    if (value === true  || value === false) {
        if (typeof item.iCheck !== "undefined") {
            item.iCheck(value ? 'check' : 'uncheck');
        } else {
            item.attr('checked', value);
        }
    }

    if(item.is('select')){
        item.val(value);
        item.trigger('change');
    }

}
MeebAutoUpdater.prototype.fillForm = function()
{
    let self = this;
    let data = localStorage.getItem(this.formType);

    if(data && data !== this.getFormValueAsArray())
    {
        this.form.prepend($('<p class="alert alert-warning">Nalezli jsme rozpracovaná data, chcete <a href="#" id="confirm_'+ this.formId +'">pokračovat</a> nebo <a href="#" id="delete_'+ this.formId +'">odstranit?</a></p>'));
        this.form.find('#confirm_' + this.formId).on('click', function (){
            $(this).closest('.alert').remove();
            self.deserializeForm();
        })
        this.form.find('#delete_' + this.formId).on('click', function (){
            $(this).closest('.alert').remove();
            self.clean();
        })
    } else {
        this.clean();
    }
}

MeebAutoUpdater.prototype.clean = function() {
    localStorage.removeItem(this.formType);
}

MeebAutoUpdater.prototype.formExist = function()
{
    if(document.forms[0]) return 1;
    else return 0;
}


MeebAutoUpdater.prototype.searchForm = function ()
{
   let field = document.querySelector("form"), counter = 0
   for(let i = 0; i < field.length; i++) { counter++ }
   if( counter > 2){ // 2, cause one is native attribute and does not represent any kind of a form
       let fields = [];
        for(let i = 0; i < counter; i++){
            fields.push(field[i].id);
        }
        return fields;
    }
   else return field.id;
}

function getPageId()
{
    let pageId = window.location.pathname, realAddress = [];
    for(let i = 0; i < pageId.length; i++)
    {
        if( !(isNaN(parseInt(pageId[i]))) ){
            realAddress.push(pageId[i]);
        }
    }
    return realAddress;
}

