"use strict";

function initToastr(di) {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    var snippetManager = di.getService('snippetManager');
    snippetManager.on('after-update', function() {
        $('.nittro-flash').each(function () {
            var elem = $(this);
            var message = $(this).html();

            if (elem.hasClass('nittro-flash-success')) {
                toastr['success'](message);
            } else if (elem.hasClass('nittro-flash-info')) {
                toastr['info'](message);
            } else if (elem.hasClass('nittro-flash-warning')) {
                toastr['warning'](message);
            } else if (elem.hasClass('nittro-flash-error')) {
                toastr['error'](message);
            }

            elem.remove();
        });
    });
}