function AutoScrollManager(di, scrollOffset) {
    var snippetManqager = di.getService('snippetManager');
    this.snippetManager = snippetManqager;
    this.scrollOffset = scrollOffset;

    this.initialize();
}

AutoScrollManager.prototype.initialize = function() {
    window.isScrollEnabled = true;

    $(document).on('click', '*[data-scroll-disabled]', function() {
        window.isScrollEnabled = false;
    });

    var self = this;
    this.snippetManager.on('before-update', function() {
        $(document).off('.autoscroll');
    });

    this.snippetManager.on('after-update', function(updateEvent) {
        $(document).on('click.autoscroll', 'a', function(e) {
            var href = $(this).attr('href');
            if (href === '#') {
                return;
            }

            if (href.charAt(0) === '#') {
                e.preventDefault();
                e.stopPropagation();

                self.scrollTo(href);
                return false;
            }
        });

        if (!window.isScrollEnabled) {
            window.isScrollEnabled = true;
            return;
        }

        if (typeof self.timeout !== "undefined" && self.timeout) {
            clearTimeout(self.timeout);
        }

        self.timeout = setTimeout(function () {
            var offset = self.scrollOffset;
            var target = $(window.location.hash);
            if (target.length > 0) {
                offset = target.offset().top;
            }

            $('html, body').animate({
                scrollTop: offset,
            }, 400);

            self.timeout = false;
        }, 50);
    });
};

AutoScrollManager.prototype.scrollTo = function(id, time) {
    if ($(id).length === 0) {
        return;
    }

    if (typeof time === 'undefined') {
        time = 500;
    }

    $('html, body').animate(
        {
            scrollTop: $(id).offset().top,
        },
        time,
        'swing'
    );
};

AutoScrollManager.prototype.disableNextScroll = function() {
    window.isScrollEnabled = false;
};