function MeebRecaptcha(key) {
    this.key = key;
}

MeebRecaptcha.prototype.initPageRequest = function() {
    let key = this.key;
    grecaptcha.ready(function() {
        grecaptcha.execute(key, { action: 'page' }).then(function(token) {
        });
    });
};

MeebRecaptcha.prototype.initRecaptchaForms = function() {
    let key = this.key;
    $('input[data-action]').each(function () {
        let $element = $(this);

        let form = $element.closest('form');
        let action = $element.data('action');
        let submit = form.find('*[type="submit"]');

        form.on('submit.meeb-recaptcha', function(e) {
            e.preventDefault();
            e.stopPropagation();

            submit.attr('disabled', 'disabled');
            grecaptcha.ready(function() {
                grecaptcha.execute(key, { action: action }).then(function(token) {
                    form.find('input[name="g_recaptcha_response"]').val(token);

                    form.off('.meeb-recaptcha');
                    submit.attr('disabled', false);
                    submit.click();
                });
            });

            return false;
        });

    });
};
