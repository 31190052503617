function MeebsterPacketa(di) {
    this.init();
    this.target = null;
    this.page = di.getService('page');
}

MeebsterPacketa.prototype.init = function() {
    let self = this;

    $('tr[data-packeta] a').click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.key = $(this).closest('tr').data('packeta');
        self.target = $(this).attr('href');
        self.show();

        return false;
    });
}

MeebsterPacketa.prototype.show = function(key) {
    let self = this;
    Packeta.Widget.pick(key, function(point) {
        self.selectPoint(point);
    });
}

MeebsterPacketa.prototype.selectPoint = function(point) {
    if (point && this.target) {
        let data = {
            packeta_id: point.id,
            packeta_name: point.place,
            packeta_address: point.zip + ' ' + point.nameStreet,
        }

        console.log(point);
        console.log(data);
        this.page.open(this.target, 'POST', data);
    }

}